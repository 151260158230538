import React from "react";
import SEO from "../components/seo";
import Slidy from "../components/slidy";
import Layout from "../components/layout";
import ProductView from "../components/productView";

const Produkte = () => {
    const fruchtaufstriche = [ // TODO: info ausbessern
        {
            name: "Apfel-Birne-Zimt Fruchtaufstrich",
            image: "Apfelbirnezimtfruchtaufstrich.png",
            alt: "Apfel-Birne-Zimt Fruchtaufstrich"
        },
        {
            name: "Himbeer Fruchtaufstrich",
            image: "Himbeerfruchtaufstrich.png",
            alt: "Himbeer Fruchtaufstrich",
        },
        {
            name: "Isabella Fruchtaufstrich",
            image: "Isabellafruchtaufstrich.png",
            alt: "Isabella Fruchtaufstrich"
        },
        {
            name: "Marille Fruchtaufstrich",
            image: "Marillefruchtaufstrich.png",
            alt: "Marille Fruchtaufstrich"
        }
    ];
    const  kerzen = [
        {
            name: "Teelichter",
            image: "Teelichter.png",
            alt: "Teelichter"
        },
        {
            name: "Bienenwachskerze",
            image: "Kerze.png",
            alt: "Bienenwachskerze"
        },
        {
            name: "Bärenkerze",
            image: "Teddy.png",
            alt: "Bärenkerze"
        }
    ]
    const kosmetik =  [
        {
            name: "Duschöl mit Honigduft",
            image: "Duschoel.png",
            alt: "Duschöl mit Honigduft"
        },
        {
            name: "Honig Cremeseife",
            image: "Honigcremeseife.png",
            alt: "Honig Cremeseife"
        },
        {
            name: "Honigwabenseife",
            image: "Honigwabenseife.png",
            alt: "Honigwabenseife"
        },
        {
            name: "Lippenbalsam",
            image: "Lippenbalsam.png",
            alt: "Lippenbalsam"
        },
        {
            name: "Propolisstift",
            image: "Propolisstift.png",
            alt: "Propolisstift"
        },
        {
            name: "Propolistropfen",
            image: "Propolistropfen.png",
            alt: "Propolistropfen"
        },
        {
            name: "Zelltonikum",
            image: "Zelltonikum.png",
            alt: "Zelltonikum"
        }
    ]
    const sliderImages = [
        {
            image: "IMG_8415.jpg",
            alt: "Verkaufsraum Innen"
        },
        {
            image: "IMG_2144.jpg",
            alt: "Bienenwachskerzen"
        },
        {
            image: "IMG_2184.jpg",
            alt: "Fruchtaufstriche"
        },
        {
            image: "IMG_2192.jpg",
            alt: "Sirupe"
        },
        {
            image: "IMG_2152.jpg",
            alt: "Fruchtsäfte"
        }
    ]

    const remainingProducts = [
        {
            name: "Milch-Honig Bonbons",
            image: "Honigmilchbonbons.png",
            alt: "Milch-Honig Bonbons"
        },
        {
            name: "Kernöl",
            image: "Kernoel.png",
            alt: "Kernöl"
        },
        {
            name: "Frucht-Honig Gummibären",
            image: "Fruchthonigbaerchen.png",
            alt: "Frucht-Honig Gummibären"
        },
    ]

    return (
        <Layout>
            <SEO title="Produkte" />
            <ProductView headline="Selbstgemachte Fruchtaufstriche." color="FFF" subHeadline="Probieren Sie unsere selbstgemachten Fruchtaufstriche. Noch mehr Varianten finden Sie bei uns vor Ort." products={fruchtaufstriche} />
            <ProductView headline="Kerzen aus Bienenwachs." color="FFF" subHeadline="Von Teelichtern aus Bienenwachs bis zu Kerzen in verschiedenen Formen: Bei uns finden Sie eine große Auswahl an Bienenwachskerzen." products={kerzen} />
            <ProductView headline="Naturkosmetik." color="FFF" subHeadline="Tun Sie Ihrem Körper Gutes, mit unseren Kosmetikprodukten. Bei uns finden Sie ausgewählte Naturkosmetik und Pflegeprodukte mit Honig." products={kosmetik} />
            <ProductView headline="Weitere Produkte." color="FFF" subHeadline="Hier finden Sie eine Auswahl an Produkten die Sie ebenfalls bei uns finden, ob Kernöl aus der Region oder Süßem mit Honig." products={remainingProducts} />
            <Slidy sliderImages={sliderImages} />
        </Layout>
    )
}

export default Produkte